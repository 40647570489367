import React from "react";

const variantClasses = { h1: "sm:text-[19px] md:text-[21px] text-[23px]" };

const Text = ({ children, className, variant, as, ...restProps }) => {
  const Component = as || "span";
  return (
    <Component
      className={`${className} ${variant && variantClasses[variant]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
