// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/PoppinsRegular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/PoppinsBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n @font-face { font-display: swap; font-style : normal; src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); font-family : \"Poppins\"; font-weight : 400;  }\n @font-face { font-display: swap; font-style : bold; src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "); font-family : \"Poppins\"; font-weight : 700;  }", "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":";CACC,aAAa,kBAAkB,EAAE,mBAAmB,EAAE,4CAA8C,EAAE,uBAAuB,EAAE,iBAAiB,GAAG;CACnJ,aAAa,kBAAkB,EAAE,iBAAiB,EAAE,4CAA2C,EAAE,uBAAuB,EAAE,iBAAiB,GAAG","sourcesContent":["\n @font-face { font-display: swap; font-style : normal; src: url(\"../assets/fonts/PoppinsRegular.ttf\"); font-family : \"Poppins\"; font-weight : 400;  }\n @font-face { font-display: swap; font-style : bold; src: url(\"../assets/fonts/PoppinsBold.ttf\"); font-family : \"Poppins\"; font-weight : 700;  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
