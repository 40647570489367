import React from 'react'
import Logo from './/assets/img_milzopoles.svg'
import Lamp from './/assets/img_closeup00001.png'
import { Img, Text } from "./components";
import Lamp_h from './/assets/Screenshot_3.png'

const App = () => {

  const handleClick = () => {
    window.location.replace('https://www.vizulo.com');
  };

  return (
    <>
      <div className='flex lg:flex-nowrap sm:flex-wrap lg:relative'>
        <div className="bg-white font-poppins lg:h-screen md:h-full sm:h-full lg:mx-auto w-full">
          <img
            src={Logo}
            className="absolute lg:h-[120px] md:h-[120px] sm:h-[80px] lg:left-[5%] md:left-[6%] sm:left-[9.5%] lg:top-[7%] md:top-[4%] sm:top-[4%] w-auto"
            alt="milzopoles"
          />
          <a
            href="www.vizulo.com"
            className="absolute sm:top-[42%] md:top-[43%] lg:top-[80%] font-pop font-normal lg:left-[16%] md:left-[6%] sm:left-[9.5%] not-italic sm:text-[19px] md:text-[21px] text-[23px] text-black_900 text-left w-auto"
            target="_blank"
            rel="noreferrer"
          >
            <Text className="">Distributed by <a onClick={handleClick}>www.vizulo.com</a></Text>

          </a>
          <Text
            className="absolute sm:top-[22%] lg:top-[59%] md:top-[23%] font-pop font-bold lg:left-[5%] md:left-[6%] sm:left-[9.5%] text-black_900 text-left w-auto"
            as="h1"
            variant="h1"
          >
            Contacts
          </Text>
          <Text
            className="absolute sm:top-[26%] lg:top-[59%] md:top-[27%] font-normal font-pop lg:left-[16%] md:left-[6%] sm:left-[9.5%] not-italic text-black_900 text-left"
            as="h1"
            variant="h1"
          >
            <>
              <p className='-mb-6'>Reinis Leveika</p>
              <br />
              <a href="mailto:reinis.leveika@milzopoles.com"><p className='-mb-6'>reinis.leveika@milzopoles.com</p></a>
              <br />
              <a href="tel:+371 2 9117176"> +371 2 9117176 </a>

            </>
          </Text>
        </div>
        <div className=" h-screen w-full hidden lg:block">
          <Img
            src={Lamp}
            className=" absolute h-screen inset-[0] mx-auto xl:ml-[32%] lg:ml-[35%]"
            alt="closeup00001"
          />
        </div>
        <div className="bg-[#CFD8DC] lg:w-[160%] sm:hidden md:hidden lg:block"></div>

        <div className="bg-[#CFD8DC] lg:hidden sm:hidden md:block absolute md:bottom-0 md:w-full md:h-[46%]">
          <Img
            src={Lamp_h}
            className=" lg:hidden sm:hidden md:block absolute inset-0 m-auto "
            alt="closeup00001"
          />
        </div>
        <div className="bg-[#CFD8DC] lg:hidden md:hidden sm:block absolute sm:bottom-0 sm:w-full sm:h-[50%]">
          <Img
            src={Lamp}
            className=" lg:hidden md:hidden sm:block absolute bottom-0"
            alt="closeup00001"
          />
        </div>

      </div>

    </>
  );
}

export default App